

body {
  font-family: $font_family_1;
  color: var(--font-color);
  background-color: var(--bg-color);
  overflow-x: hidden;
  margin: 0;
  backface-visibility: hidden;

  * {
    outline: none !important;
    touch-action: manipulation; // disable double tap zoom on IOs
    backface-visibility: hidden;
  }
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  @include respond-to(x-small, df) {
    font-size: 16px !important; //Note: IOs zooms page for anything less than this for input fields. 
    font-weight: 500;
  }
}
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active,
input:-internal-autofill-selected {
    -webkit-box-shadow: 0 0 0 30px var(--bg-color) inset !important; 
    -webkit-text-fill-color: var(--font-color) !important;
}


// TEXT STYLES
.text-color {
  color: $font_color_1 !important;

  &-medium {
    color: $font_color_2 !important;
  }

  &-light {
    color: $font_color_3 !important;
  }
}

.text-color-highlight {
  color: $theme-color-1 !important;
}

.text-color-highlight-2 {
  color: $theme-color-2 !important;
}

.text-italic {
  font-style: italic !important;
}
.font-weight-lighter {
  font-weight: 200 !important;
}
.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-heavy {
  font-weight: 800 !important;
}


.text-responsive {
  font-size: calc(100% + 1vw + 1vh);
  &--small{
    font-size: calc(100% + 0.5vw + 0.5vh);
  }
}

.base-font {
  font-size: #{$default_font_size} !important;

  &--small {
    font-size: $default_font_size - 2 !important;
  }

  &--large {
    font-size: $default_font_size + 2 !important;
  }

  &--x-large {
    font-size: $default_font_size + 4 !important;
  }
}

.font-family-1 {
  font-family: $font_family_1;
}

// .font-family-2 {
//   font-family: $font_family_2;
// }
.theme-font {
  font-family: $theme_font;
}
.ellipsis {
  &-one-line {
    @include ellipsis-txt;
  }

  &-two-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    // line-height: 1.7rem;
  }

}


//TEMPLATE STYLES
.h-auto {
  height: auto !important;
}

.w-auto {
  width: auto !important;
}
.d-grid{
  display: grid !important;
}
.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.top-0 {
  top: 0;
}

.cursor-pointer{
  cursor: pointer;
}
.hide-cursor {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: none;
  z-index: 100;
}

.overflow-hidden {
  overflow: hidden !important;
}

.bg-theme {
  background-color: $theme-color-1;
}

.remove-bg-sm {
  @include respond-to(medium, df) {
    background-color: transparent !important;
  }
}

.vertical-separator {
  position: relative;
  padding: 0 10px;

  &:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    height: 100%;
    border-right: 1px solid $border-color;
  }
}

.disable-section {
  pointer-events: none;
  color: $disable_font_color !important;
  background: $disable_color;
}

.price {

  &--large {
    font-size: 1.3rem;
  }

}

/* ==================== Effects ================== */

/* Ripple effect */

.ripple {
  background-position: center;
  transition: background 0.8s;
}

.ripple:hover {
  background: $theme-color-1 radial-gradient(circle, transparent 1%, $theme-color-1 1%) center/15000%;
  color: $white !important;
}

// @media only screen and (max-width : 768px) {
// 	.active {
// 		/*CSS transitions*/
// 		-o-transition-property: none !important;
// 		-moz-transition-property: none !important;
// 		-ms-transition-property: none !important;
// 		-webkit-transition-property: none !important;
// 		transition-property: none !important;
// 		/*CSS transforms*/
// 		-o-transform: none !important;
// 		-moz-transform: none !important;
// 		-ms-transform: none !important;
// 		-webkit-transform: none !important;
// 		transform: none !important;
// 		/*CSS animations*/
// 		-webkit-animation: none !important;
// 		-moz-animation: none !important;
// 		-o-animation: none !important;
// 		-ms-animation: none !important;
// 		animation: none !important;
// 	}
// }