$social-icon-size: 54px;
.cr-footer {
    min-height: auto;
    background-color: var(--dark-bg-color);
    color: var(--dark-text-color);

    @include respond-to(medium) {
        // min-height: 400px;
    }
    .border-right{
        border-color: var(--muted-color) !important
    }
    .social {
        display: grid;
        grid-template-columns: $social-icon-size $social-icon-size $social-icon-size;
        grid-gap: 1.5rem;
        align-items: center;

        a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            border: 2px solid var(--icon-color-2);
            width: $social-icon-size;
            height: $social-icon-size;
            margin: 0 8px 0 0;
            font-size: 18px;
            color: var(--icon-color-2);
            text-decoration: none;
            transition: all 200ms ease-in-out;

            &:hover {
                // color: var(--primary-hover-color-2);
                // border-color: var(--primary-hover-color-2);
                filter: brightness(1.2);
                &.social__f{
                    color: $material-indigo-400;
                    border-color:$material-indigo-400;
                }
                &.social__fm{
                    color: $material-blue-400;
                    border-color:$material-blue-400;
                }
                
                &.social__i{
                    color: $material-purple-300;
                    border-color:$material-purple-300;
                }
            }
        }
    }

    &-logo {
        color:$white;
        font-family: $theme_font;
        font-size: 5rem;
        text-shadow: 0 0 0.2rem rgba($font_color_2,0.24), 0 0 0.1rem var(--logo-color),
      -0.1rem 0.1rem 1rem var(--logo-color), 0.1rem 0.1rem 0.6rem var(--logo-color),
      0 -0.2rem 1rem $font_color_2, 0 0.2rem 1rem var(--logo-color);
      @include respond-to(medium){
        font-size: 7.5rem;
      }
    }
    &-footnote{
        letter-spacing: 0.08rem;
    }
}