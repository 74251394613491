
/* mr-de-haviland-regular - latin */
@font-face {
    font-family: 'Mr De Haviland';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/Mr_De_Haviland/mr-de-haviland-v9-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Mr De Haviland Regular'), local('MrDeHaviland-Regular'),
         url('../../fonts/Mr_De_Haviland/mr-de-haviland-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/Mr_De_Haviland/mr-de-haviland-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/Mr_De_Haviland/mr-de-haviland-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/Mr_De_Haviland/mr-de-haviland-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/Mr_De_Haviland/mr-de-haviland-v9-latin-regular.svg#MrDeHaviland') format('svg'); /* Legacy iOS */
  }

  
/* raleway-100 - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-100.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-100.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-300 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-200 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-500 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-regular - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-600 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-700 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-800 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-900 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-100italic - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-100italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-100italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-200italic - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-200italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-200italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-300italic - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-italic - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-500italic - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-600italic - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-700italic - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-800italic - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-800italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-800italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-900italic - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local(''),
         url('../../fonts/Raleway/raleway-v18-latin-900italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('../../fonts/Raleway/raleway-v18-latin-900italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
