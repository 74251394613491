
//material box-shadow
@mixin box-shadow-lvl-1($shadow-color:$black, $type: null){
  box-shadow: $type 0 1px 3px rgba($shadow-color,0.13), $type 0 1px 3px rgba($shadow-color,0.20);
}
@mixin box-shadow-lvl-2($shadow-color:$black, $type: null){
  box-shadow: $type 0 3px 6px rgba($shadow-color,0.13), $type 0 3px 6px rgba($shadow-color,0.20);
}
@mixin box-shadow-lvl-3($shadow-color:$black, $type: null){
  box-shadow: $type 0 10px 20px rgba($shadow-color,0.16), $type 0 6px 6px rgba($shadow-color,0.23);
}
@mixin box-shadow-lvl-4($shadow-color:$black, $type: null){
  box-shadow: $type 0 14px 28px rgba($shadow-color,0.25), $type 0 10px 10px rgba($shadow-color,0.22);
}
@mixin box-shadow-lvl-5($shadow-color:$black, $type: null){
  box-shadow: $type 0 19px 38px rgba($shadow-color,0.30), $type 0 15px 12px rgba($shadow-color,0.22);
}

@mixin box-shadow-inset($shadow-color:$black){
  box-shadow: inset 0 0 4px rgba($color: $shadow-color, $alpha: 0.8),inset 0 0 10px rgba($color: $shadow-color, $alpha: 0.2);
}

//background
@mixin background-overlay--diagonal-top-left($angle:135deg, $color:$black){
  background: rgba($color, 0.5);
  background: linear-gradient($angle, rgba($color,0.68) 0%,rgba($color,0.12) 82%,rgba($color,0) 95%,rgba($color,0) 96%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}



@mixin background-overlay--vertical($direction : top,$color:$black, $amount:100%){
  background: rgba($color, 0.4);
  background: linear-gradient(to $direction, transparent 0%, rgba($color,0.45) $amount);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed', GradientType=0 );
}



//MISC
@mixin material-transition{
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
@mixin custom-transition($type: all,$time: 0.3s){
  transition: $type $time cubic-bezier(.25,.8,.25,1);
}

@mixin flex-box($justify : flex-start, $align : normal, $direction : row, $wrap : nowrap){
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
  flex-wrap: $wrap;
}

@mixin ellipsis-txt{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin ellipsis-txt-2-line{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 18px, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * ($lineCount + 1);
  //text-align: justify;
  //margin-bottom: -1em;
  padding-bottom: 1em;
  &:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    //width: 1em;
    //height: 1em;
    //margin-top: 0.2em;
    width: 100%;
    height: $lineHeight;
    background: $bgColor;
  }
  &:after {
    content: '...';
    position: absolute;
    left: 0;
    bottom: 1px;
    font-size: 24px;
    //font-weight: 600;
  }
}
@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after { clear: both; }
}
@mixin multiLineEllipsis_inline($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  //text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

@mixin rotate_180{
  transform: rotate(180deg);
}


//FOR animation
@mixin animation ( $animation, $duration: 200ms, $fill-mode:forwards, $anim: cubic-bezier(.25,.8,.25,1),  $delay:0s, $count: 1) {
  -webkit-animation-name: $animation;
  -webkit-animation-duration: $duration;
  -webkit-animation-fill-mode: $fill-mode; /* this prevents the animation from restarting! */
  -webkit-animation-timing-function: $anim;
  -webkit-animation-delay: $delay;
  -webkit-animation-iteration-count: $count;

  -moz-animation-name: $animation;
  -moz-animation-duration: $duration;
  -moz-animation-fill-mode: $fill-mode; /* this prevents the animation from restarting! */
  -moz-animation-timing-function: $anim;
  -moz-animation-delay: $delay;
  -moz-animation-iteration-count: $count;

  -o-animation-name: $animation;
  -o-animation-duration: $duration;
  -o-animation-fill-mode: $fill-mode; /* this prevents the animation from restarting! */
  -o-animation-timing-function: $anim;
  -o-animation-delay: $delay;
  -o-animation-iteration-count: $count;

  animation-name: $animation;
  animation-duration: $duration;
  animation-fill-mode: $fill-mode; /* this prevents the animation from restarting! */
  animation-timing-function: $anim;
  animation-delay: $delay;
  animation-iteration-count: $count;
}


//TARGET SPECIFIC DEVICES/BROWSERS
@mixin target_browser($browser) {
  @if $browser == ie {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      @content;
    }
  } @else if ($browser == edge) {
    @supports (-ms-accelerator:true) {
      @content;
    }
  } @else if ($browser == safari) {
    @media not all and (min-resolution: .001dpcm) {
      @supports (-webkit-appearance:none) {
        @content;
      }
    }
  } @else if ($browser == ios) { //special case for safari on iOS
    @supports (-webkit-overflow-scrolling: touch) {
      @content;
    }
  }
  //@else if ($browser == ios) {
  //  @media screen and (-webkit-min-device-pixel-ratio:0) and (max-device-width:1024px){
  //      @content;
  //  }
  //}

}