@font-face {
  font-family: 'cr-icon';
  src:  url('fonts/cr-icon.eot?y99lj6');
  src:  url('fonts/cr-icon.eot?y99lj6#iefix') format('embedded-opentype'),
    url('fonts/cr-icon.ttf?y99lj6') format('truetype'),
    url('fonts/cr-icon.woff?y99lj6') format('woff'),
    url('fonts/cr-icon.svg?y99lj6#cr-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.cr-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cr-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.5rem;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.cr-light-f .path1:before {
  content: "\e900";
  color: rgb(129, 212, 250);
}
.cr-light-f .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(79, 195, 247);
}
.cr-light-f .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(255, 214, 0);
}
.cr-light-f .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 0);
}
.cr-light-f .path5:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 255, 0);
}
.cr-light-f .path6:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(255, 255, 0);
}
.cr-light-f .path7:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(255, 255, 0);
}
.cr-light-f .path8:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(255, 253, 231);
}
.cr-light-f .path9:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(255, 249, 196);
}
.cr-light-f .path10:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(255, 255, 0);
}
.cr-light-f .path11:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(255, 255, 0);
}
.cr-light-f .path12:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(255, 255, 0);
}
.cr-light-f .path13:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(255, 255, 0);
}
.cr-light-f .path14:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-light-f .path15:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-light-f .path16:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-night-f .path1:before {
  content: "\e910";
  color: rgb(47, 96, 137);
}
.cr-night-f .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(38, 78, 112);
}
.cr-night-f .path3:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-night-f .path4:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(144, 164, 174);
}
.cr-night-f .path5:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.cr-night-f .path6:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.cr-night-f .path7:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(176, 190, 197);
}
.cr-night-f .path8:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(176, 190, 197);
}
.cr-night-f .path9:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(176, 190, 197);
}
.cr-night-f .path10:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(176, 190, 197);
}
.cr-night-f .path11:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(176, 190, 197);
}
.cr-night-f .path12:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(176, 190, 197);
}
.cr-night-f .path13:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-night-f .path14:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-group:before {
  content: "\e91e";
}
.cr-group-f .path1:before {
  content: "\e91f";
  color: rgb(144, 124, 175);
}
.cr-group-f .path2:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(116, 92, 151);
}
.cr-group-f .path3:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(179, 157, 219);
}
.cr-group-f .path4:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(149, 117, 205);
}
.cr-group-f .path5:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(237, 231, 246);
}
.cr-group-f .path6:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(209, 196, 233);
}
.cr-group-f .path7:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(209, 196, 233);
}
.cr-group-f .path8:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(149, 117, 205);
}
.cr-group-f .path9:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(237, 231, 246);
}
.cr-group-f .path10:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(209, 196, 233);
}
.cr-group-f .path11:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-group-f .path12:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-like:before {
  content: "\e92b";
}
.cr-like-f .path1:before {
  content: "\e92c";
  color: rgb(103, 135, 199);
}
.cr-like-f .path2:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(66, 103, 178);
}
.cr-like-f .path3:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(2, 136, 209);
}
.cr-like-f .path4:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(2, 119, 189);
}
.cr-like-f .path5:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(225, 245, 254);
}
.cr-like-f .path6:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(179, 229, 252);
}
.cr-like-f .path7:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-like-f .path8:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-like-f .path9:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-love:before {
  content: "\e935";
}
.cr-love-f .path1:before {
  content: "\e936";
  color: rgb(240, 98, 146);
}
.cr-love-f .path2:before {
  content: "\e937";
  margin-left: -1em;
  color: rgb(236, 64, 122);
}
.cr-love-f .path3:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(252, 228, 236);
}
.cr-love-f .path4:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(249, 201, 218);
}
.cr-love-f .path5:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-love-f .path6:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-love-f .path7:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-mail:before {
  content: "\e93d";
}
.cr-mail-f .path1:before {
  content: "\e93e";
  color: rgb(255, 202, 40);
}
.cr-mail-f .path2:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(255, 179, 0);
}
.cr-mail-f .path3:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(255, 248, 225);
}
.cr-mail-f .path4:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(255, 224, 130);
}
.cr-mail-f .path5:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(255, 224, 130);
}
.cr-mail-f .path6:before {
  content: "\e943";
  margin-left: -1em;
  color: rgb(255, 248, 225);
}
.cr-mail-f .path7:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.cr-mail-f .path8:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-mail-f .path9:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-upload:before {
  content: "\e947";
}
.cr-upload-f .path1:before {
  content: "\e948";
  color: rgb(120, 144, 156);
}
.cr-upload-f .path2:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(96, 125, 139);
}
.cr-upload-f .path3:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(236, 239, 241);
}
.cr-upload-f .path4:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(207, 216, 220);
}
.cr-upload-f .path5:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(207, 216, 220);
}
.cr-upload-f .path6:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
.cr-upload-f .path7:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(55, 71, 79);
}
