// ---------------------------------------------------------------------------------------------------------------------
// Google's Material Design Colors
// https://www.google.com/design/spec/style/color.html#color-color-palette
// ---------------------------------------------------------------------------------------------------------------------
$material-red:                      #F44336;
$material-pink:                     #E91E63;
$material-purple:                   #9C27B0;
$material-deep-purple:              #673AB7;
$material-indigo:                   #3F51B5;
$material-blue:                     #2196F3;
$material-light-blue:               #03A9F4;
$material-cyan:                     #00BCD4;
$material-teal:                     #009688;
$material-green:                    #4CAF50;
$material-light-green:              #8BC34A;
$material-lime:                     #CDDC39;
$material-yellow:                   #FFEB3B;
$material-amber:                    #FFC107;
$material-orange:                   #FF9800;
$material-deep-orange:              #FF5722;
$material-brown:                    #795548;
$material-grey:                     #9E9E9E;
$material-blue-grey:                #607D8B;
$material-black:                    #000000;
$material-white:                    #FFFFFF;

// ---------------------------------------------------------------------------------------------------------------------
// Google's Material Design Palettes
// https://www.google.com/design/spec/style/color.html#color-color-palette
// ---------------------------------------------------------------------------------------------------------------------
$material-red-50:                   #FFEBEE;
$material-red-100:                  #FFCDD2;
$material-red-200:                  #EF9A9A;
$material-red-300:                  #E57373;
$material-red-400:                  #EF5350;
$material-red-500:                  #F44336;
$material-red-600:                  #E53935;
$material-red-700:                  #D32F2F;
$material-red-800:                  #C62828;
$material-red-900:                  #B71C1C;

$material-pink-50:                  #FCE4EC;
$material-pink-100:                 #F8BBD0;
$material-pink-200:                 #F48FB1;
$material-pink-300:                 #F06292;
$material-pink-400:                 #EC407A;
$material-pink-500:                 #E91E63;
$material-pink-600:                 #D81B60;
$material-pink-700:                 #C2185B;
$material-pink-800:                 #AD1457;
$material-pink-900:                 #880E4F;

$material-purple-50:                #F3E5F5;
$material-purple-100:               #E1BEE7;
$material-purple-200:               #CE93D8;
$material-purple-300:               #BA68C8;
$material-purple-400:               #AB47BC;
$material-purple-500:               #9C27B0;
$material-purple-600:               #8E24AA;
$material-purple-700:               #7B1FA2;
$material-purple-800:               #6A1B9A;
$material-purple-900:               #4A148C;

$material-deep-purple-50:           #EDE7F6;
$material-deep-purple-100:          #D1C4E9;
$material-deep-purple-200:          #B39DDB;
$material-deep-purple-300:          #9575CD;
$material-deep-purple-400:          #7E57C2;
$material-deep-purple-500:          #673AB7;
$material-deep-purple-600:          #5E35B1;
$material-deep-purple-700:          #512DA8;
$material-deep-purple-800:          #4527A0;
$material-deep-purple-900:          #311B92;

$material-indigo-50:                #E8EAF6;
$material-indigo-100:               #C5CAE9;
$material-indigo-200:               #9FA8DA;
$material-indigo-300:               #7986CB;
$material-indigo-400:               #5C6BC0;
$material-indigo-500:               #3F51B5;
$material-indigo-600:               #3949AB;
$material-indigo-700:               #303f9F;
$material-indigo-800:               #283593;
$material-indigo-900:               #1A237E;

$material-blue-50:                  #E3F2FD;
$material-blue-100:                 #BBDEFB;
$material-blue-200:                 #90CAF9;
$material-blue-300:                 #64B5F6;
$material-blue-400:                 #42A5F5;
$material-blue-500:                 #2196F3;
$material-blue-600:                 #1E88E5;
$material-blue-700:                 #1976D2;
$material-blue-800:                 #1565C0;
$material-blue-900:                 #0D47A1;

$material-light-blue-50:            #E1F5FE;
$material-light-blue-100:           #B3E5FC;
$material-light-blue-200:           #81D4FA;
$material-light-blue-300:           #4FC3F7;
$material-light-blue-400:           #29B6F6;
$material-light-blue-500:           #03A9F4;
$material-light-blue-600:           #039BE5;
$material-light-blue-700:           #0288D1;
$material-light-blue-800:           #0277BD;
$material-light-blue-900:           #01579B;

$material-cyan-50:                  #E0F7FA;
$material-cyan-100:                 #B2EBF2;
$material-cyan-200:                 #80DEEA;
$material-cyan-300:                 #4DD0E1;
$material-cyan-400:                 #26C6DA;
$material-cyan-500:                 #00BCD4;
$material-cyan-600:                 #00ACC1;
$material-cyan-700:                 #0097A7;
$material-cyan-800:                 #00838F;
$material-cyan-900:                 #006064;

$material-teal-50:                  #E0F2F1;
$material-teal-100:                 #B2DFDB;
$material-teal-200:                 #80CBC4;
$material-teal-300:                 #4DB6AC;
$material-teal-400:                 #26A69A;
$material-teal-500:                 #009688;
$material-teal-600:                 #00897B;
$material-teal-700:                 #00796B;
$material-teal-800:                 #00695C;
$material-teal-900:                 #004D40;

$material-green-50:                 #E8F5E9;
$material-green-100:                #C8E6C9;
$material-green-200:                #A5D6A7;
$material-green-300:                #81C784;
$material-green-400:                #66BB6A;
$material-green-500:                #4CAF50;
$material-green-600:                #43A047;
$material-green-700:                #388E3C;
$material-green-800:                #2E7D32;
$material-green-900:                #1B5E20;

$material-light-green-50:           #F1F8E9;
$material-light-green-100:          #DCEDC8;
$material-light-green-200:          #C5E1A5;
$material-light-green-300:          #AED581;
$material-light-green-400:          #9CCC65;
$material-light-green-500:          #8BC34A;
$material-light-green-600:          #7CB342;
$material-light-green-700:          #689F38;
$material-light-green-800:          #558B2F;
$material-light-green-900:          #33691E;

$material-lime-50:                  #F9FBE7;
$material-lime-100:                 #F0F4C3;
$material-lime-200:                 #E6EE9C;
$material-lime-300:                 #DCE775;
$material-lime-400:                 #D4E157;
$material-lime-500:                 #CDDC39;
$material-lime-600:                 #C0CA33;
$material-lime-700:                 #AFB42B;
$material-lime-800:                 #9E9D24;
$material-lime-900:                 #827717;

$material-yellow-50:                #FFFDE7;
$material-yellow-100:               #FFF9C4;
$material-yellow-200:               #FFF59D;
$material-yellow-300:               #FFF176;
$material-yellow-400:               #FFEE58;
$material-yellow-500:               #FFEB3B;
$material-yellow-600:               #FDD835;
$material-yellow-700:               #FBC02D;
$material-yellow-800:               #F9A825;
$material-yellow-900:               #F57F17;

$material-amber-50:                 #FFF8E1;
$material-amber-100:                #FFECB3;
$material-amber-200:                #FFE082;
$material-amber-300:                #FFD54F;
$material-amber-400:                #FFCA28;
$material-amber-500:                #FFC107;
$material-amber-600:                #FFB300;
$material-amber-700:                #FFA000;
$material-amber-800:                #FF8F00;
$material-amber-900:                #FF6F00;

$material-orange-50:                #FFF3E0;
$material-orange-100:               #FFE0B2;
$material-orange-200:               #FFCC80;
$material-orange-300:               #FFB74D;
$material-orange-400:               #FFA726;
$material-orange-500:               #FF9800;
$material-orange-600:               #FB8C00;
$material-orange-700:               #F57C00;
$material-orange-800:               #EF6C00;
$material-orange-900:               #E65100;

$material-deep-orange-50:           #FBE9E7;
$material-deep-orange-100:          #FFCCBC;
$material-deep-orange-200:          #FFAB91;
$material-deep-orange-300:          #FF8A65;
$material-deep-orange-400:          #FF7043;
$material-deep-orange-500:          #FF5722;
$material-deep-orange-600:          #F4511E;
$material-deep-orange-700:          #E64A19;
$material-deep-orange-800:          #D84315;
$material-deep-orange-900:          #BF360C;

$material-brown-50:                 #EFEBE9;
$material-brown-100:                #D7CCC8;
$material-brown-200:                #BCAAA4;
$material-brown-300:                #A1887F;
$material-brown-400:                #8D6E63;
$material-brown-500:                #795548;
$material-brown-600:                #6D4C41;
$material-brown-700:                #5D4037;
$material-brown-800:                #4E342E;
$material-brown-900:                #3E2723;

$material-grey-50:                  #FAFAFA;
$material-grey-100:                 #F5F5F5;
$material-grey-200:                 #EEEEEE;
$material-grey-300:                 #E0E0E0;
$material-grey-400:                 #BDBDBD;
$material-grey-500:                 #9E9E9E;
$material-grey-600:                 #757575;
$material-grey-700:                 #616161;
$material-grey-800:                 #424242;
$material-grey-900:                 #212121;

$material-blue-grey-50:             #ECEFF1;
$material-blue-grey-100:            #CFD8DC;
$material-blue-grey-200:            #B0BEC5;
$material-blue-grey-300:            #90A4AE;
$material-blue-grey-400:            #78909C;
$material-blue-grey-500:            #607D8B;
$material-blue-grey-600:            #546E7A;
$material-blue-grey-700:            #455A64;
$material-blue-grey-800:            #37474F;
$material-blue-grey-900:            #263238;

// ---------------------------------------------------------------------------------------------------------------------
// Google's Material Design Accents
// https://www.google.com/design/spec/style/color.html#color-color-palette
// ---------------------------------------------------------------------------------------------------------------------
$material-red-a100:                 #FF8A80;
$material-red-a200:                 #FF5252;
$material-red-a400:                 #FF1744;
$material-red-a700:                 #D50000;

$material-pink-a100:                #FF80AB;
$material-pink-a200:                #FF4081;
$material-pink-a400:                #F50057;
$material-pink-a700:                #C51162;

$material-purple-a100:              #EA80FC;
$material-purple-a200:              #E040FB;
$material-purple-a400:              #D500F9;
$material-purple-a700:              #AA00FF;

$material-deep-purple-a100:         #B388FF;
$material-deep-purple-a200:         #7C4DFF;
$material-deep-purple-a400:         #651FFF;
$material-deep-purple-a700:         #6200EA;

$material-indigo-a100:              #8C9EFF;
$material-indigo-a200:              #536DFE;
$material-indigo-a400:              #3D5AFE;
$material-indigo-a700:              #304FFE;

$material-blue-a100:                #82B1FF;
$material-blue-a200:                #448AFF;
$material-blue-a400:                #2979FF;
$material-blue-a700:                #2962FF;

$material-light-blue-a100:          #80D8FF;
$material-light-blue-a200:          #40C4FF;
$material-light-blue-a400:          #00B0FF;
$material-light-blue-a700:          #0091EA;

$material-cyan-a100:                #84FFFF;
$material-cyan-a200:                #18FFFF;
$material-cyan-a400:                #00E5FF;
$material-cyan-a700:                #00B8D4;

$material-teal-a100:                #A7FFEB;
$material-teal-a200:                #64FFDA;
$material-teal-a400:                #1DE9B6;
$material-teal-a700:                #00BFA5;

$material-green-a100:               #B9F6CA;
$material-green-a200:               #69F0AE;
$material-green-a400:               #00E676;
$material-green-a700:               #00C853;

$material-light-green-a100:         #CCFF90;
$material-light-green-a200:         #B2FF59;
$material-light-green-a400:         #76FF03;
$material-light-green-a700:         #64DD17;

$material-lime-a100:                #F4FF81;
$material-lime-a200:                #EEFF41;
$material-lime-a400:                #C6FF00;
$material-lime-a700:                #AEEA00;

$material-yellow-a100:              #FFFF8D;
$material-yellow-a200:              #FFFF00;
$material-yellow-a400:              #FFEA00;
$material-yellow-a700:              #FFD600;

$material-amber-a100:               #FFE57F;
$material-amber-a200:               #FFD740;
$material-amber-a400:               #FFC400;
$material-amber-a700:               #FFAB00;

$material-orange-a100:              #FFD180;
$material-orange-a200:              #FFAB40;
$material-orange-a400:              #FF9100;
$material-orange-a700:              #FF6D00;

$material-deep-orange-a100:         #FF9E80;
$material-deep-orange-a200:         #FF6E40;
$material-deep-orange-a400:         #FF3D00;
$material-deep-orange-a700:         #DD2C00;

// ---------------------------------------------------------------------------------------------------------------------
// Google's Material Design Text Colors
// https://www.google.com/design/spec/style/color.html#color-ui-color-application
// ---------------------------------------------------------------------------------------------------------------------
$material-text-light-primary:       rgba(#000000, 0.87);
$material-text-light-secondary:     rgba(#000000, 0.54);
$material-text-light-disabled:      rgba(#000000, 0.38);
$material-text-light-divider:       rgba(#000000, 0.12);

$material-text-dark-primary:        rgba(#FFFFFF, 1.00);
$material-text-dark-secondary:      rgba(#FFFFFF, 0.70);
$material-text-dark-disabled:       rgba(#FFFFFF, 0.30);
$material-text-dark-divider:        rgba(#FFFFFF, 0.12);

// ---------------------------------------------------------------------------------------------------------------------
// Google's Material Design Themes
// https://www.google.com/design/spec/style/color.html#color-themes
// ---------------------------------------------------------------------------------------------------------------------
$material-theme-light-status:       #0E0E0E;
$material-theme-light-app:          #F5F5F5;
$material-theme-light-background:   #FAFAFA;
$material-theme-light-card:         #FFFFFF;

$material-theme-dark-status:        #000000;
$material-theme-dark-app:           #212121;
$material-theme-dark-background:    #303030;
$material-theme-dark-card:          #424242;