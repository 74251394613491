$breakpoints: (
        'x-small': 600px,
        'small': 767px,
        'medium': 900px,
        'large': 1200px,
        'x-large': 1500px
) !default;


@mixin respond-to($breakpoint, $device:mf) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @if ($device == df) { // device first
      @media (max-width: map-get($breakpoints, $breakpoint)) {
        @content;
      }
    }@else { // mobile first
      @media (min-width: map-get($breakpoints, $breakpoint)) {
        @content;
      }
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

//Landscape Orientation
@mixin landscape(){
  @media (orientation: landscape) {
    @content
  }
}